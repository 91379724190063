import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const BlogLeader = () => {
  return (
    <div class="bg-gray-200 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div class="relative max-w-7xl mx-auto">
        <h2 class="text-center font-display text-5xl mb-2">From the blog...</h2>

        <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-6xl">
          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden transition ease-in-out transform hover:-translate-y-2">
            <div class="flex-shrink-0">
              <StaticImage
                src="../../images/9.jpg"
                layout="fullWidth"
                aspectRatio={6 / 4}
                placeholder="blurred"
                alt="Spring Clean"
                objectFit="contain"
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <a href="/events/spring-clean-2024" class="block mt-2">
                  <p class="font-semibold text-sm uppercase text-green-700 mb-2">
                    Upcoming event
                  </p>
                  <p class="text-3xl font-semibold font-serif text-gray-900">
                    Spring Clean 2024
                  </p>
                  <p class="mt-3 text-base text-gray-500">
                    Do you love where you live? Sevenoaks District residents are
                    being invited to roll up their sleeves this 6th & 7th April
                    for the fourth annual "Spring Clean".
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden mb-10 sm:mb-0 transition ease-in-out transform hover:-translate-y-2">
            <div class="flex-shrink-0">
              <StaticImage
                src="../../images/3.jpg"
                placeholder="blurred"
                layout="fullWidth"
                aspectRatio={6 / 4}
                alt="St Clere Estate"
                objectFit="contain"
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <a href="/blog/rubbish-at-st-clere" class="block mt-2">
                  <p class="font-semibold text-sm uppercase text-green-700 mb-2">
                    Blog post
                  </p>
                  <p class="text-3xl font-semibold font-serif text-gray-900">
                    Rubbish at St Clere
                  </p>
                  <p class="mt-3 text-base text-gray-500">
                    As hard as the team at St Clere work to look after the
                    landscape, we never seem to be able to keep up with those
                    who are hell-bent on ruining it.
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden transition ease-in-out transform hover:-translate-y-2">
            <div class="flex-shrink-0">
              <StaticImage
                src="../../images/9.jpg"
                layout="fullWidth"
                aspectRatio={6 / 4}
                placeholder="blurred"
                alt="Spring Clean"
                objectFit="contain"
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <a href="/events/sevenoaks-spring-clean" class="block mt-2">
                  <p class="font-semibold text-sm uppercase text-green-700 mb-2">
                    Previous event
                  </p>
                  <p class="text-3xl font-semibold font-serif text-gray-900">
                    Sevenoaks Spring Clean 2023
                  </p>
                  <p class="mt-3 text-base text-gray-500">
                    Our third annual 'Spring Clean' Weekend took place in April
                    2023. Over a thousand black bags of rubbish were collected!
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogLeader
