import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "fontsource-libre-baskerville"
import "fontsource-kalam"

import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer/index.js"
import BlogLeader from "../components/Blog"

const IndexPage = () => (
  <>
    <SEO title="CleanUp" />
    <Header imageSrc="images/5.jpg" />

    <div class="relative bg-green-700">
      <div class="h-1/3 md:h-56 bg-green-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <StaticImage
          className="w-full h-full opacity-90 object-cover"
          src="../images/hedge_rubbish.jpg"
          placeholder="blurred"
          layout="fullWidth"
          alt="Dumped rubbish in a country hedgerow"
        />
      </div>
      <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-12 lg:py-16">
        <div class="md:ml-auto md:w-1/2 md:pl-10">
          <p class="lg:ml-12 mt-12 text-5xl leading-snug font-extrabold font-serif text-white">
            Kent,
          </p>
          <p class=" lg:ml-12 text-6xl leading-snug font-extrabold font-display  text-white">
            this is rubbish.
          </p>
          <p class="mt-5 lg:ml-12 text-lg font-bold text-white">
            Our community is privileged to have some of the best green spaces in
            the country.
          </p>

          <p class="mt-5 lg:ml-12 text-lg text-white mb-12">
            Unfortunately, there are some amongst us who take that for granted
            and are impacting the health and cleanliness of our local
            environment.
          </p>
        </div>
      </div>
    </div>

    <div class="relative  bg-blue-600">
      <div class="h-full md:h-56 bg-green-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <StaticImage
          className="w-full h-full opacity-90 object-contain"
          src="../images/3.jpg"
          placeholder="blurred"
          layout="fullWidth"
          alt="Beautiful Sevenoaks countryside"
        />
      </div>
      <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-12 lg:py-16">
        <div class="md:ml-auto md:w-1/2 md:pl-10">
          <p class="lg:ml-12 text-5xl leading-snug font-extrabold font-serif text-white">
            How can I help?
          </p>
          <p class="lg:ml-12  mt-5 text-lg font-bold text-white fon">
            Every action, little or large, will make a big difference to
            building a cleaner, healthier environment for us all
          </p>
          <div class="lg:ml-12 mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            <p>
              <span class="mt-1 block text-base text-gray-300">
                <span class="font-medium text-white">
                  Pledge to pick up one bag of litter per week
                </span>
              </span>
            </p>
            <p>
              <span class="mt-1 block text-base text-gray-300">
                <span class="font-medium text-white">
                  Join our Facebook group
                </span>
              </span>
            </p>
            <p>
              <span class="mt-1 block text-base text-gray-300">
                <span class="font-medium text-white">
                  Join a community event
                </span>
              </span>
            </p>

            <p>
              <span class="mt-1 block text-base text-gray-300">
                <span class="font-medium text-white">
                  Tell us about a rubbish hotspot
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <BlogLeader />

    <div class="relative bg-gray-800">
      <div class="h-80 absolute bottom-0 xl:inset-0 xl:h-auto xl:grid xl:grid-cols-2">
        <div class="h-full xl:relative xl:col-start-2">
          <StaticImage
            className="h-full w-full object-cover opacity-25 absolute xl:inset-0"
            src="../images/4.jpg"
            placeholder="blurred"
            layout="fullWidth"
            alt="Sevenoaks in Snow"
          />
          <div
            aria-hidden="true"
            class="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-800 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
          ></div>
        </div>
      </div>
      <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div class="relative pt-24 pb-64 sm:pt-36 sm:pb-64 xl:col-start-1 xl:pb-36">
          <p class="mt-3 text-5xl font-extrabold font-serif text-white leading-snug">
            Making a real difference
          </p>
          <p class="mt-5 text-lg text-gray-300 sm:px-10 md:px-0 lg:px-0">
            Our members are coming together to tackle the problem head on.
          </p>
          <div class="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            <p>
              <span class="block text-6xl font-bold text-white">545</span>
              <span class="mt-1 block text-base text-gray-300">
                <span class="font-medium text-white">
                  Members in our Facebook group
                </span>
              </span>
              <a
                href="https://www.facebook.com/groups/cleanupsevenoaks"
                target="_blank"
                rel="noopener"
                style={{ backgroundColor: "#4267B2" }}
                class="mt-5 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Join us on Facebook
              </a>
            </p>

            <p>
              <span class="block text-6xl font-bold text-white">
                Thousands!
              </span>
              <span class="mt-1 block text-base text-gray-300">
                <span class="font-medium text-white">
                  of black bags of rubbish collected
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </>
)

export default IndexPage
